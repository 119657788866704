import "./pin.css";
import { Marker } from "react-map-gl";
import pinAvailable from "./pinAvailable.png";
import pinInUse from "./pinInUse.png";
import pinOutOfService from "./pinOutOfService.png";
import pinHalfHalf from "./pinHalfHalf.png";
import pinQuarterInUse from "./pinQuarterInUse.png";
import pinQuarterAvailable from "./pinQuarterAvailable.png";
import { PinProps } from "./PinProps";
import { LocationStatus } from "../../Models/map";

/**
 * This function creates a marker
 * @returns marker component
 */
export default function Pin(props: PinProps) {
  const evseCount = props.location.chargePoints.reduce(
    (accumulator, currentValue) =>
      accumulator + (currentValue.evses ? currentValue.evses.length : 0),
    0
  );

  const onClick = () => {
    props.onClick(props.location);
  };

  const renderPinImage = () => {
    switch (props.location.status) {
      case LocationStatus.Available:
        return (
          <img
            src={pinAvailable}
            key={props.location.id.toString()}
            id={props.location.id.toString()}
            alt="Location with available charge points"
          />
        );
      case LocationStatus.InUse:
        return (
          <img
            src={pinInUse}
            key={props.location.id.toString()}
            id={props.location.id.toString()}
            alt="All charge points at this location are currently in use"
          />
        );
      case LocationStatus.LessThanHalfInUse:
        return (
          <img
            src={pinQuarterInUse}
            key={props.location.id.toString()}
            id={props.location.id.toString()}
            alt="Less than half of chargers at this location are in use"
          />
        );
      case LocationStatus.BetweenHalfAndThreeQuartersInUse:
        return (
          <img
            src={pinHalfHalf}
            key={props.location.id.toString()}
            id={props.location.id.toString()}
            alt="Between half and three quarters of chargers at this location are in use"
          />
        );
      case LocationStatus.MoreThanThreeQuartersInUse:
        return (
          <img
            src={pinQuarterAvailable}
            key={props.location.id.toString()}
            id={props.location.id.toString()}
            alt="More than three quarters of chargers at this location are in use"
          />
        );
      default:
        return (
          <img
            src={pinOutOfService}
            key={props.location.id.toString()}
            id={props.location.id.toString()}
            alt="Location is out of service"
          />
        );
    }
  };

  return (
    <Marker
      key={props.location.id}
      latitude={props.location.geoPosition.latitude}
      longitude={props.location.geoPosition.longitude}
      anchor="bottom"
    >
      <button
        key={`button-${props.location.name}`}
        id={props.location.id.toString()}
        className="marker-btn"
        onClick={onClick}
      >
        <div className="evse-count">{evseCount}</div>
        <div>{renderPinImage()}</div>
      </button>
    </Marker>
  );
}
