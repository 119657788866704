import { EvseProps } from "./EvseProps";
import getConnectorDetailsFromType from "../../Utils/utils";
import "./evse.css";
import { Status } from "../../Models/map";

/**
 * This component renders a SingleEvse details within all connectors on the Side Panel
 * @returns the Single Evse component with the correct connector icons and details
 */
export default function Evse(props: EvseProps) {
  return (
    <div className="singleEvse" data-testid="singleEvse">
      <div className="detialsandIconContainer">
        <div className="singleEvseDetails">
          <p>
            <b>
              {(props.evse.maxPower / 1000).toFixed(1)}kW{" "}
              {props.evse.currentType.toUpperCase()}
            </b>
          </p>
          <p>ID: {props.evse?.id}</p>
          <p
            className={
              props.evse?.status === Status.Available
                ? "available"
                : props.evse?.status === Status.InUse
                ? "inUse"
                : props.evse?.status === Status.Disabled
                ? "disabled"
                : "outOfService"
            }
          >
            <b>
              {props.evse?.status === Status.Available
                ? "available"
                : props.evse?.status === Status.InUse
                ? "charging"
                : props.evse?.status === Status.Disabled
                ? "disabled"
                : "coming soon"}
            </b>
          </p>
        </div>
        <div className="singleEvseConnectorIcons">
          {props.evse?.connectors?.map((connector) => {
            const connectorDetails = getConnectorDetailsFromType(
              connector.type
            );
            return (
              <div key={connector.type} className="singleConnectorIcon">
                <img src={connectorDetails.src} alt={connectorDetails.alt} />
                <p>{connectorDetails.name}</p>
              </div>
            );
          })}
        </div>
      </div>

      {props.evse.pricePerKwh && (
        <div className="singleEvseTariff">
          <p className="tariff">£{props.evse.pricePerKwh?.toFixed(2)}</p>
          <p className="perkWhtext">per kWh</p>
        </div>
      )}
    </div>
  );
}
