import { Marker } from "react-map-gl";
import { ClusterProps } from "./ClusterProps";
import "./cluster.css";

/**
 * This component renders a cluster with an onclick function providing location info for cluster expansion zoom.
 * @returns the cluster component
 */
export default function Cluster(props: ClusterProps) {
  return (
    <Marker
      longitude={props.longitude}
      latitude={props.latitude}
      onClick={() => {
        props.onClick(props.id, props.longitude, props.latitude);
      }}
    >
      <div className="cluster-marker" id={props.id.toString()}>{props.count}</div>
    </Marker>
  );
}
