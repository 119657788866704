import "./legend.css";
import pinAvailable from "../Pin/pinAvailable.png";
import pinInUse from "../Pin/pinInUse.png";
import pinOutOfService from "../Pin/pinOutOfService.png";

/**
 * This component is for the legend on the map
 * @returns the legend component explaining what the three pins mean
 */
export default function Legend() {
  return (
    <div className="legend" data-testid="legend">
      <img src={pinAvailable} alt="Legend for available pin" />
      <p>Available</p>
      <img src={pinInUse} alt="Legend for in use pin" />
      <p>Charging</p>
      <img src={pinOutOfService} alt="Legend for out of service pin" />
      <p>Coming soon</p>
    </div>
  );
}
