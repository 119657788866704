import { ConnectorType } from "../Models/map";
import ccsIcon from '../Components/LocationPanel/ccsIcon.png';
import chademoIcon from '../Components/LocationPanel/chademoIcon.png'
import type1Icon from '../Components/LocationPanel/type1Icon.png'
import type2Icon from '../Components/LocationPanel/type2Icon.png'
import type3Icon from '../Components/LocationPanel/type3Icon.png'
import banana from '../Components/LocationPanel/banana.png'

const connectorTypeMapping = {
  [ConnectorType.Type1]: {
    name: "Type 1",
    src: type1Icon,
    alt: "Type 1 connector"    
  },
  [ConnectorType.Type2]: {
    name: "Type 2",
    src: type2Icon,
    alt: "Type 2 connector"    
  },    
  [ConnectorType.Type3]: {
    name: "Type 3",
    src: type3Icon,
    alt: "Type 3 connector"    
  },    
  [ConnectorType.CHAdeMO]: {
    name: "CHAdeMO",
    src: chademoIcon,
    alt: "Chademo connector"    
  },    
  [ConnectorType.CCS1]: {
    name: "CCS 1",
    src: ccsIcon,
    alt: "CCS connector"    
  },
  [ConnectorType.CCS2]: {
    name: "CCS",
    src: ccsIcon,
    alt: "CCS connector"    
  },
  [ConnectorType.CATARC]: {
    name: "CATARC",
    src: banana,
    alt: "Catarc connector"    
  },
  [ConnectorType.Schuko]: {
    name: "Schuko",
    src: banana,
    alt: "Schuko connector"    
  },
  [ConnectorType.Supercharger]: {
    name: "Supercharger",
    src: banana,
    alt: "Supercharger connector"    
  },
  [ConnectorType.CEE16]: {
    name: "CEE16",
    src: banana,
    alt: "CEE16 connector"    
  },
  [ConnectorType.CEE32]: {
    name: "CEE32",
    src: banana,
    alt: "CEE32 connector"    
  },
  [ConnectorType.J1772]: {
    name: "J1772",
    src: banana,
    alt: "J1772 connector"    
  },
  [ConnectorType.Inductive]: {
    name: "Inductive",
    src: banana,
    alt: "Inductive connector"    
  },
  [ConnectorType.Nema520]: {
    name: "NEMA 5-20",
    src: banana,
    alt: "Nema520 connector"    
  },
  [ConnectorType.TypeEFrench]: {
    name: "Type E French",
    src: banana,
    alt: "Type E French connector"    
  },
  [ConnectorType.TypeGBritish]: {
    name: "Type G British",
    src: banana,
    alt: "Type G British connector"    
  },
  [ConnectorType.TypeJSwiss]: {
    name: "Type J Swiss",
    src: banana,
    alt: "Type J Swiss connector"    
  },
  [ConnectorType.AVCON]: {
    name: "Avcon",
    src: banana,
    alt: "Type Avcon connector"    
  },
  "default": {
    name: "Uknown",
    src: banana,
    alt: "Uknown connector"    
  },
}
export default function getConnectorDetailsFromType (connectorType: ConnectorType) {
  return (connectorType in connectorTypeMapping) ? connectorTypeMapping[connectorType] : connectorTypeMapping["default"]
}
