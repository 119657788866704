import "./LocationPanel.css";
import { LocationPanelProps } from "./LocationPanelProps";
import { IChargePoint, IConnector, IEvse } from "../../Models/map";
import getConnectorDetailsFromType from "../../Utils/utils";
import * as timeago from "timeago.js";
import Evse from "../Evse/Evse";
import CloseIcon from "@mui/icons-material/Close";
import { isMobile } from "react-device-detect";
import { hashMapper } from "aws-cdk-lib";

/**
 * This component renders a panel showing the details of a location including charge points and evses.
 * @returns the locationPanel component with all the details about the specific location when the user clicks on a pin
 */

export default function LocationPanel(props: LocationPanelProps) {
  let connectorTypes: {
    [key: string]: any;
  }[] = [];

  const noneDisabledChargePoints = props.location?.chargePoints.filter(
    (chargePoint) => chargePoint.status !== "disabled"
  );

  props.location?.chargePoints.forEach((chargePoint: IChargePoint) => {
    chargePoint.evses?.forEach((evse: IEvse) => {
      let connectorPower = evse.maxPower;
      let connectorCurrent = evse.currentType;
      evse.connectors?.forEach((connector: IConnector) => {
        let connectorTypeAndPower = {
          connectorType: connector.type,
          connectorPower,
          connectorCurrent,
        };
        connectorTypes.push(connectorTypeAndPower);
      });
    });
  });

/**
 * Filters through all available connectors and only keeps the unique 
 * ones with the heighest connectorPower 
 */
const uniqueConnectorTypes = Object.values(connectorTypes.reduce((previous,current) => {
  if(current.connectorType in previous) {
    if(current.connectorPower > previous[current.connectorType].connectorPower)
    previous[current.connectorType] = Object.assign({},current);
  } else {
    previous[current.connectorType] = Object.assign({}, current);
  }
  return previous;
},{}));

  return (
    <div
      className={isMobile ? "isMobile" : "isDesktop"}
      data-testid="locationPanel"
    >
      {!isMobile && <CloseIcon onClick={props.closeDrawer} id="closeIcon" />}
      <div className="locationPanelHeader">
        <div id="locationName">
          <h2>{props.location.name}</h2>
        </div>
        <div id="postCode">
          <h4>{props.location.postCode}</h4>
        </div>
      </div>

      <div className="connectorIcons">
        {uniqueConnectorTypes
          .map((connector, index) => {
            const connectorDetails = getConnectorDetailsFromType(
              connector.connectorType
            );
            return (
              <div key={index}>
                <img src={connectorDetails.src} alt={connectorDetails.alt} />
                <p>
                  <b>{connectorDetails.name}</b>
                </p>
                <p>
                  {(connector.connectorPower / 1000).toFixed(1)}kW{" "}
                  {connector.connectorCurrent.toUpperCase()}
                </p>
              </div>
            );
          })}
      </div>

      <div className="lastUpdated">
        <p>Last updated: {timeago.format(props.location.lastUpdated)}</p>
      </div>

      <div className="connectors">
        {noneDisabledChargePoints.map((chargePoint) => {
          return chargePoint?.evses?.map((evse) => (
            <Evse key={evse.id} evse={evse} />
          ));
        })}
      </div>
    </div>
  );
}
