import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import "./app.css";
import Map from "./Components/Map/Map";
import zestLogo from "./zestLogo.png";

/**
 * Boundary coordinates for the UK
 */
export const ukBounds: [number, number, number, number] = [
  -11.5, 49.55, 3, 59.5,
];

export default function App() {
  return (
    <div className="container">
      <AppBar
        position="fixed"
        className="header"
        data-testid="header"
        sx={{ bgcolor: "white" }}
        style={{ zIndex: 2 }}
      >
        <Toolbar>
          <a href="https://www.zest.uk.com/drivers" target="_blank">
            <img src={zestLogo} alt="Zest company logo" />
          </a>
        </Toolbar>
      </AppBar>
      <div className="contents">
        <Map
          initialBounds={ukBounds}
          mapBoxToken={process.env.REACT_APP_MAPBOX_TOKEN || ""}
        />
      </div>
    </div>
  );
}
