import { LngLatLike, useMap } from "react-map-gl";
import { ClustersProps } from "./ClustersProps";
import { IChargePoint, ILocation } from "../../Models/map";
import useSupercluster from "use-supercluster";
import { ClusterGeoJsonProperties } from "../../Models/ClusterGeoJsonProperties";
import Cluster from "../Cluster/Cluster";
import Pin from "../Pin/Pin";
import { AnyProps, ClusterProperties, PointFeature } from "supercluster";

export default function Clusters(props: ClustersProps) {
  const { current: map } = useMap();

  /**
   * Called when a location is clicked
   * @param location The location selected
   */
  const onLocationClick = (location: ILocation) => {
    props.onLocationClick(location);
  };

  /**
   * The onClusterClick function takes the parameters of the cluster and sets a expansion zoom
   * level for when the cluster is clicked.
   * @param clusterId
   * @param clusterLongitude
   * @param clusterLatitude
   */
  const onClusterClick = (
    clusterId: number,
    clusterLongitude: number,
    clusterLatitude: number
  ) => {
    const zoom = supercluster?.getClusterExpansionZoom(clusterId);
    const centre: LngLatLike = {
      lng: clusterLongitude,
      lat: clusterLatitude,
    };

    if (map) {
      map.flyTo({
        center: centre,
        zoom: zoom,
      });
    }
  };

  /**
   * Loop through each of the locations:
   * - convert to an array of GeoJSON features for the super cluster package
   */
  const points: PointFeature<ClusterGeoJsonProperties>[] = props.locations.map(
    (location: ILocation) => ({
      type: "Feature",
      properties: { cluster: false, location: location },
      geometry: {
        type: "Point",
        coordinates: [
          location.geoPosition.longitude,
          location.geoPosition.latitude,
        ],
      },
    })
  );

  // Call the supercluster hook to get the cluster data
  const { clusters, supercluster } = useSupercluster<ClusterGeoJsonProperties>({
    points,
    zoom: props.zoom,
    bounds: props.bounds,
    // options passed onto supercluster, radius how big the cluster zones are,
    // max zoom about level clusters appear at
    options: { radius: 50, maxZoom: 40 },
  });

  return (
    <div>
      {clusters.map(
        (
          cluster:
            | PointFeature<ClusterGeoJsonProperties>
            | PointFeature<ClusterProperties & AnyProps>
        ) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster } = cluster.properties;

          if (isCluster) {
            const { cluster_id: clusterId } = cluster.properties;

            // Calculate the number of EVSEs under the cluster
            const clusterLeaves =
              clusterId && supercluster?.getLeaves(clusterId, Infinity);

            let evseCount = 0;

            if (clusterLeaves) {
              clusterLeaves.forEach((leaf) => {
                leaf.properties.location.chargePoints.forEach(
                  (chargePoint: IChargePoint) => {
                    if (chargePoint.evses) {
                      evseCount += chargePoint.evses.length;
                    }
                  }
                );
              });
            }

            return (
              <Cluster
                key={Number(clusterId)}
                id={Number(clusterId)}
                longitude={longitude}
                latitude={latitude}
                count={evseCount}
                onClick={onClusterClick}
              />
            );
          } else {
            const { location } = cluster.properties;
            return (
              <Pin
                key={location.id}
                location={location}
                onClick={onLocationClick}
              />
            );
          }
        }
      )}
    </div>
  );
}
