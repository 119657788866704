import React, { FormEvent, useEffect, useState, useRef } from "react";
import "./filter.css";
import filterIcon from "./filterIcon.png";
import { FilterProps } from "./FilterProps";
import {
  Box,
  FormGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Slider,
} from "@mui/material";
import { CurrentType } from "../../Models/map";
import { isMobile } from "react-device-detect";

/**
 * This component is for the Filter on the map
 * @returns the Filter component for the user to only see the locations with specified
 * connector types, power, current and format
 */
export default function Filter(props: FilterProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLImageElement | null>(null);

  const [selectedAc, setSelectedAc] = useState(props.filters.ac);
  const [selectedDc, setSelectedDc] = useState(props.filters.dc);

  const powerMarks = [
    {
      value: 0,
      power: 3,
      label: "3kW",
    },
    {
      value: 1,
      power: 7,
      label: "7kW",
    },
    {
      value: 2,
      power: 11,
      label: "11kW",
    },
    {
      value: 3,
      power: 22,
      label: "22kW",
    },
    {
      value: 4,
      power: 50,
      label: "50kW",
    },
    {
      value: 5,
      power: 100,
      label: "100kW",
    },
    {
      value: 6,
      power: 350,
      label: "350kW",
    },
  ];

  /**
   * Converts the power coming from the App into the value number on the slider
   */
  function powerValConvertor(power: number) {
    const updatedVal = powerMarks.filter(
      (powerMark) => powerMark.power === power
    );
    return updatedVal[0]?.value;
  }

  const [selectedMinPowerSliderValue, setSelectedMinPowerSliderValue] =
    React.useState<number>(powerValConvertor(props.filters.minPower));
  const [selectedMaxPowerSliderValue, setSelectedMaxPowerSliderValue] =
    React.useState<number>(powerValConvertor(props.filters.maxPower));

  function powerMarkValueText(value: number) {
    return powerMarks[value].label;
  }

  function powerMarkValueLabelFormat(value: number) {
    return powerMarks[value].label;
  }

  /**
   * Closes the dropdown if a user clicks outside of the filter panel
   */
  useEffect(() => {
    const handleClickOutside: any = (event: FormEvent<HTMLElement>) => {
      const isDropdownClick =
        dropdownRef.current &&
        dropdownRef?.current?.contains(event.target as Node);
      const isButtonClick =
        buttonRef.current && buttonRef?.current?.contains(event.target as Node);

      if (isDropdownClick || isButtonClick) {
        // If the ref is not defined or the user clicked on the menu, we don’t do anything.
        return;
      }
      // Otherwise we close the menu.
      setIsOpen(false);
    };

    document.addEventListener("mousedown", handleClickOutside); // handle desktops
    document.addEventListener("touchstart", handleClickOutside); // handle touch devices

    // Event cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // handle desktops
      document.removeEventListener("touchstart", handleClickOutside); // handle touch devices
    };
  }, [dropdownRef, buttonRef]);

  /**
   * Checks for filter changes and resets the locally saved filter values
   */
  useEffect(() => {
    if (props.filters.ac !== selectedAc) {
      setSelectedAc(props.filters.ac);
    }
    if (props.filters.dc !== selectedDc) {
      setSelectedDc(props.filters.dc);
    }
    if (
      powerValConvertor(props.filters.minPower) !== selectedMinPowerSliderValue
    ) {
      setSelectedMinPowerSliderValue(powerValConvertor(props.filters.minPower));
    }
    if (
      powerValConvertor(props.filters.maxPower) !== selectedMaxPowerSliderValue
    ) {
      setSelectedMaxPowerSliderValue(powerValConvertor(props.filters.maxPower));
    }
  }, [props.filters]);

  /**
   * Sets the 'selectedAc' state. It checks if the 'checked/unchecked'
   * current type is in the array, if it isn't, it adds it in if checked. If it is,
   * it takes it off(unchecked). Applies filters and sends them to the Map component.
   * @param event
   */
  const handleAcCurrentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked === true) {
      setSelectedAc(true);
    } else {
      setSelectedAc(false);
    }
    const min = powerMarks[selectedMinPowerSliderValue].power;
    const max = powerMarks[selectedMaxPowerSliderValue].power;
    handleApply(event.target.checked, selectedDc, min, max);
  };

  /**
   * Sets the 'selectedDc' state. It checks if the 'checked/unchecked'
   * current type is in the array, if it isn't, it adds it in if checked. If it is,
   * it takes it off(unchecked). Applies filters and sends them to the Map component.
   * @param event
   */
  const handleDcCurrentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked === true) {
      setSelectedDc(true);
    } else {
      setSelectedDc(false);
    }
    const min = powerMarks[selectedMinPowerSliderValue].power;
    const max = powerMarks[selectedMaxPowerSliderValue].power;
    handleApply(selectedAc, event.target.checked, min, max);
  };

  /**
   * Sets the 'selectedMinPowerSlideValue' and the 'selectedMaxPowerSlideValue' to the min.
   * or max power the user picks. Applies filters and sends them to the Map component.
   * @param event
   * @param newValues - an array of min and max to allow for filtering for both min and max
   **/
  const handleSliderChange = (event: Event, newValues: number | number[]) => {
    if (Array.isArray(newValues)) {
      const minVal = newValues[0];
      const maxVal = newValues[1];
      setSelectedMinPowerSliderValue(minVal);
      setSelectedMaxPowerSliderValue(maxVal);
      const min = powerMarks[minVal].power;
      const max = powerMarks[maxVal].power;
      handleApply(selectedAc, selectedDc, min, max);
    }
  };

  /**
   * Updates all the filters and sends them back to the map comopnent
   */
  const handleApply = (ac: boolean, dc: boolean, min: number, max: number) => {
    props.onFiltersUpdated({
      ac: ac,
      dc: dc,
      minPower: min,
      maxPower: max,
    });
  };

  /**
   * Clears all the selections on the filter
   */
  const handleClearAllFilters = () => {
    setIsOpen(false);
    props.onFiltersCleared();
  };

  return (
    <div className={isMobile ? "mobileFilter" : "desktopFilter"} data-testid="filter">
      <div
        className="filterButton"
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
      >
        <img src={filterIcon} alt="Filter icon" />
        <p>Filters</p>
      </div>

      {isOpen && (
        <div
          ref={dropdownRef}
          className={
            isMobile ? "mobileFilterDropdown" : "desktopFilterDropdown"
          }
        >
          <div
            className={
              isMobile ? "mobileFilterContent" : "desktopFilterContent"
            }
          >
            <Box className="currentType">
              <FormControl>
                <FormLabel className="formLabel">Charger Type:</FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    className="formControlLabel"
                    label="Fast"
                    value={CurrentType.AC}
                    control={
                      <Checkbox
                        checked={selectedAc}
                        onChange={handleAcCurrentChange}
                      />
                    }
                  />
                  <FormControlLabel
                    className="formControlLabel"
                    label="Rapid"
                    value={CurrentType.DC}
                    control={
                      <Checkbox
                        checked={selectedDc}
                        onChange={handleDcCurrentChange}
                      />
                    }
                  />
                </FormGroup>
              </FormControl>
            </Box>

            <Box className="sliderBox">
              <FormLabel className="formLabel">Power (kW):</FormLabel>
              <Slider
                value={[
                  selectedMinPowerSliderValue,
                  selectedMaxPowerSliderValue,
                ]}
                className="slider"
                min={0}
                max={powerMarks.length - 1}
                getAriaLabel={() => "Custom power marks"}
                defaultValue={0}
                sx={{
                  width: "90%",
                  marginLeft: "10px",
                  color: "rgb(1, 62, 97)",
                  "& .MuiSlider-thumb": {
                    borderRadius: "50px",
                    backgroundColor: "rgb(1, 62, 97)",
                  },
                  "& .MuiSlider-markLabel": {
                    fontSize: "11px",
                  },
                }}
                valueLabelFormat={powerMarkValueLabelFormat}
                onChange={handleSliderChange}
                getAriaValueText={powerMarkValueText}
                step={1}
                valueLabelDisplay="auto"
                marks={powerMarks}
              />
            </Box>
          </div>
          <div
            className={
              isMobile
                ? "mobileFilterDropdownActions"
                : "desktopFilterDropdownActions"
            }
          >
            <p onClick={handleClearAllFilters}>Reset Filters</p>
            <p id="doneButton" onClick={() => setIsOpen(!isOpen)}>
              Done
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
