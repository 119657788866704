import { BottomDrawerProps } from "./BottomDrawerProps";
import { useSwipeable, SwipeEventData } from "react-swipeable";
import { useState } from "react";
import Drawer from "@mui/material/Drawer";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import "./BottomDrawer.css";

/**
 * This component renders a drawer built using mui drawer that is swipable and render on screen sizes < 700.
 * @returns the Bottom Drawer component
 */
export default function BottomDrawer(props: BottomDrawerProps) {
  const [drawerHeight, setDrawerHeight] = useState(150);
  /**
   * Sets the isDrawerOpen toggle to false
   */
  const closeDrawer = () => {
    props.onClose();
  };
  /** takes absY number which is the number of the length of swipe and sets this as drawer height responding to swipe.
   * Sets max height for swipe to 500 to *stop swiping the whole size of the screen.
   * @param eventData from useSwipeable function
   */
  const onSwipeUp = (eventData: SwipeEventData) => {
    setDrawerHeight(500);
  };
  /** On swipe down reset drawer height to minimum 150 */
  const onSwipeDown = (eventData: SwipeEventData) => { 
    if(drawerHeight === 155) {
      closeDrawer();
    }
    setDrawerHeight(155);
  };

  /** Uses useSwipeable to action our own functions based on swipeUp/SwipeDown data
   */
  const handlers = useSwipeable({
    onSwipedUp: (eventData: SwipeEventData) => onSwipeUp(eventData),
    onSwipedDown: (eventData: SwipeEventData) => onSwipeDown(eventData),
  });

  return (
    <div className="bottomDrawer" {...handlers} data-test-id="zest - mobile_swipeable_bottom_drawer">
      <Drawer
        anchor={"bottom"}
        open={true}
        onClose={closeDrawer}
        variant={"persistent"}
        ModalProps={{ hideBackdrop: true }}
        PaperProps={{ sx: { width: "100%", height: drawerHeight } }}
        style={{ zIndex: 1 }}
      >
        <div className="dragBar" data-test-id="zest - drag_handle_icon">
          <DragHandleIcon sx={{ fontSize: 20 }} />
        </div>
        {props.children}
      </Drawer>
    </div>
  );
}
