import Drawer from "@mui/material/Drawer";
import { SideDrawerProps } from "./SideDrawerProps";
import "./SideDrawer.css";
/**
 * This component renders a drawer built using mui drawer that is static and render on screen sizes > 700.
 * @returns the side drawer component
 */
export default function SideDrawer(props: SideDrawerProps) {
  /**
   * Sets the isDrawerOpen toggle to false
   */
  const closeDrawer = () => {
    props.onClose();
  };

  return (
    <div className="sideDrawer">
      <Drawer
        anchor={"left"}
        open={true}
        onClose={closeDrawer}
        variant={"persistent"}
        ModalProps={{ hideBackdrop: true }}
        PaperProps={{ sx: { width: "30%" } }}
        style={{ zIndex: 1 }}
      >
        {props.children}
      </Drawer>
    </div>
  );
}
