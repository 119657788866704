import * as React from "react";
import ReactMapGl, { NavigationControl, GeolocateControl } from "react-map-gl";
import "./map.css";
import { MapProps } from "./MapProps";
import Legend from "../Legend/Legend";
import LocationPanel from "../LocationPanel/LocationPanel";
import { CurrentType, IChargePoint, IEvse, ILocation } from "../../Models/map";
import useSwr from "swr";
import { BBox } from "geojson";
import BottomDrawer from "../BottomDrawer/BottomDrawer";
import SideDrawer from "../SideDrawer/SideDrawer";
import Toolbar from "@mui/material/Toolbar";
import Clusters from "../Clusters/Clusters";
import SearchBar from "../SearchBar/SearchBar";
import { isMobile } from "react-device-detect";
import Filter from "../Filter/Filter";
import { Filters } from "../Filter/FilterProps";

/**
 * The fetcher here is an async function that accepts the url to make an API request for the map data.
 * @param url
 * @returns map data and parses it.
 */
const fetcher = (url: RequestInfo) =>
  fetch(url).then((response) => response.json());

/**
 * This component renders a map built using mapbox API as well as the SidePanel
 * @returns the Map component
 */
export default function Map(props: MapProps) {
  const [locations, setLocations] = React.useState<ILocation[]>([]);
  const [selectedLocation, setSelectedLocation] = React.useState<ILocation>();
  const [bounds, setBounds] = React.useState<BBox>();
  const [zoom, setZoom] = React.useState<number>(5);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const initialFilters = {
    ac: true,
    dc: true,
    minPower: 3,
    maxPower: 350,
  };

  const [filters, setFilters] = React.useState<Filters>(
    structuredClone(initialFilters)
  );

  /**
   * Resets the filters using the initial values
   */
  const resetFilters = (): void => {
    setFilters(initialFilters);
  };

  /**
   * Sets the currently selected location to display in the side panel
   * @param location The location selected
   */
  const onLocationClick = (location: ILocation) => {
    setSelectedLocation(location);
    openDrawer();
  };
  /**
   * Sets the isDrawerOpen toggle to true
   */
  const openDrawer = () => {
    setIsDrawerOpen(true);
  };
  /**
   * Sets the isDrawerOpen toggle to false
   */
  const closeDrawer = () => {
    setSelectedLocation(undefined);
    setIsDrawerOpen(false);
  };

  // Build the API url and call the swr hook to fetch the map data
  const url = `${process.env.PUBLIC_URL}/MapData.json`;
  const { data, error } = useSwr(url, fetcher, {
    // Refresh the map data every minute
    refreshInterval: 60000,
  });

  /**
   * Sets the locations to the updated locations with all the filters
   */
  React.useEffect(() => {
    if (data && !error) {
      let updatedLocations: ILocation[] = [];

      updatedLocations = data.locations
        //Returns all locations with charge points that have evses of specified current type i.e.AC/DC.
        .filter((location: ILocation) =>
          location.chargePoints?.some((chargePoint: IChargePoint) =>
            chargePoint.evses?.some((evse: IEvse) => {
              if (filters.ac === true) {
                if (evse.currentType === CurrentType.AC) {
                  return evse;
                }
              }
              if (filters.dc === true) {
                if (evse.currentType === CurrentType.DC) {
                  return evse;
                }
              }
            })
          )
        )
        //Returns all locations with charge points that have evses of specified power i.e.22kW
        .filter((location: ILocation) =>
          location.chargePoints?.some((chargePoint: IChargePoint) =>
            chargePoint.evses?.some(
              (evse: IEvse) =>
                evse.maxPower >= filters.minPower * 1000 &&
                evse.maxPower <= filters.maxPower * 1000 //need to be multiplied by 1000 as API data comes in Watts
            )
          )
        );

      setLocations(updatedLocations);
    }
  }, [data, error, filters]);

  /**
   * Update the selected location if the data for the location has been updated
   */
  React.useEffect(() => {
    let location: ILocation[] = [];
    if (selectedLocation) {
      location = locations.filter(
        (location: ILocation) => location.name === selectedLocation.name
      );
    }

    if (location.length === 1) {
      setSelectedLocation(location[0]);
    }
  }, [locations, selectedLocation]);

  return (
    <div className="mainContainer" data-testid="mainContainer">
      {selectedLocation && isMobile && (
        <BottomDrawer onClose={closeDrawer}>
          <LocationPanel
            location={selectedLocation}
            closeDrawer={closeDrawer}
          />
        </BottomDrawer>
      )}
      {selectedLocation && !isMobile && (
        <SideDrawer onClose={closeDrawer}>
          <Toolbar />
          <LocationPanel
            location={selectedLocation}
            closeDrawer={closeDrawer}
          />
        </SideDrawer>
      )}

      {error ? (
        <div className="errorMessage">
          There is a problem fetching the map data
        </div>
      ) : (
        <div
          className={`map ${isDrawerOpen && !isMobile ? "mapDrawerOpen" : ""}`}
          data-testid="map"
        >
          <ReactMapGl
            initialViewState={{
              bounds: props.initialBounds,
            }}
            onLoad={(evt) =>
              setBounds(evt.target.getBounds().toArray().flat() as BBox)
            }
            onMove={(evt) => setZoom(evt.viewState.zoom)}
            mapStyle="mapbox://styles/mapbox/streets-v11"
            mapboxAccessToken={props.mapBoxToken}
            // disable the default attribution
            attributionControl={false}
          >
            <NavigationControl position={"bottom-right"} />
            <Clusters
              bounds={bounds as BBox}
              zoom={zoom}
              locations={locations}
              onLocationClick={onLocationClick}
            />
            <GeolocateControl
              position="bottom-right"
              positionOptions={{ enableHighAccuracy: true }}
              trackUserLocation={true}
            />

            <SearchBar mapBoxToken={props.mapBoxToken} position="top-left" />

            <Filter
              onFiltersUpdated={setFilters}
              onFiltersCleared={resetFilters}
              filters={filters}
              position="top-left"
            />
          </ReactMapGl>
          <div
            className={isDrawerOpen ? "legendDrawerOpen" : "legendDrawerClosed"}
          >
            <Legend />
          </div>
        </div>
      )}
    </div>
  );
}
